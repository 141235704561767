import * as React from "react";
import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";

import BottomLeftCorner from "../svg/bottom_left_corner.svg";
import BottomRightCorner from "../svg/bottom_right_corner.svg";
import MainSectionLogo from "../svg/main_section_logo.svg";
import TopLeftCorner from "../svg/top_left_corner.svg";
import TopRightCorner from "../svg/top_right_corner.svg";
import { FRONT_DOMAIN } from "../config";

import "../scss/main.scss";
import {useEffect} from "react";
import ChangeLanguage from "../components/changeLanguage";

const CybercityPage = ({location}) => {

    React.useEffect(() => {
        const scrollContainer = document.querySelector('.site-wrapper');

        if(scrollContainer) {
            scrollContainer.scrollTop = 0;
        }
    }, []);

    useEffect(() => {
        document.body.classList.add('_without-layout');
    }, []);

    return (
        <>
            <Helmet>
                <title>Cybercity | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
            </Helmet>

            <section className="installation-img-section">
                <img className="" src="/static/img/7_Cybercity.jpg" alt=""/>
            </section>

            <section className="site-section site-section--installation">
                <ChangeLanguage location={location}/>
                <div className="content-section" style={ { "--width": "72rem" } }>

                    <h2 className="content-section__title">CYBERCITY</h2>

                    <div className="content-section__text">
                        <p>CYBERCITY is a 10-minute fractal art film. The film features a series of otherworldly, impossible environments that generated with fractal algorithms and cutting-edge procedural methods.</p>
                    </div>
                    <br/>

                    <div className="green">
                        <h2 className="content-section__title">ARTIST — JULIUS HORSTHUIS</h2>
                        <div className="content-section__text">
                            <p>Julius Horsthuis is a world-renowned, award-winning digital artist. Known for his pioneering fractal art compositions, Julius’ work has been shown in major galleries and festivals worldwide.</p>
                        </div>
                    </div>

                    <br/>

                    <div className="green">
                        <h2 className="content-section__title">COMPOSER — DAVID LEVY</h2>
                        <div className="content-section__text">
                            <p>David Levy is a composer and sound designer. He has scored a variety of games and films for several key studios and networks, such as Bethesda Softworks and HBO Max.</p>
                        </div>
                    </div>

                    <MainSectionLogo className="content-section__logo" />

                </div>
            </section>
        </>
    )
}

export default CybercityPage;
